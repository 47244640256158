html {
  scroll-behavior: smooth;
}

body {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Cormorant Garamond", serif;
}
/* h1 {
  font-family: "Catamaran", sans-serif;
} */
/* 
a {
  font-family: "Lato", sans-serif;
}

h2 {
  font-family: "Raleway", sans-serif;
}

h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
} */

.swiper-pagination {
  margin-bottom: 30px;
}

.swiper-pagination-bullet-active {
  background-color: #064663 !important;
}

.swiper-button-next {
  background-image: url(../assets/images/arrowright.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-right: 15px;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(../assets/images/arrowleft.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  margin-left: 15px;
}

.swiper-button-prev::after {
  display: none;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation-name: slide-up;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
